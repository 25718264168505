export const AppConfig = {
    HIDDEN_MENU_COUNT: 1,
    HIDDEN_MENU_COUNT_STEP: 1000,
    HIDDEN_MENU_COUNT_THERESHOLD: 500,
    PROMOTION_WARNING_SEC: 5000
}

export const AppAction = {
    APP_NEW_SESSION: 'APP_NEW_SESSION',
}

export const StorageKey = {
    SESSION_ID: 'scr_session_id',
    BRANCH_CODE: 'branch_code',
    BRANCH_ID: 'branch_id',
    POCKET_KEY: 'pocket_key',
    POCKETBASE_AUTH: 'pocketbase_auth',
    POCKET_TOKEN: 'pocket_token',
    PROMO_ID: 'promo_id',
    PROMO_TYPE: 'promo_type',
    PROMO_CODE: 'promo_code',
    PROMO_AMOUNT: 'promo_amount',
    PROMO_USE: 'promo_use',
    DSLRBOOTH_ENDPOINT: 'dslrbooth_endpoint',
    DSLRBOOTH_PASSWORD: 'dslrbooth_password',
    DSLRBOOTH_PORT: 'dslrbooth_port',
    SHOW_MORE_PAYMENT_OPTION: 'show_more_payment_option',
    BRANCH_INTERFACE: 'branch_interface'
}

export const DslrBoothAction = {
    START: 'start',
}

export const DslrBoothMode = {
    PRINT: 'print',
}

export const PaymentType = {
    FREE: 'FREE',
    PROMPTPAY: 'PROMPTPAY',
    TRUEMONEY: 'TRUEMONEY',
    ALIPAY: 'ALIPAY',
    LINK: 'LINK',
}

export const PaymentChannel = {
    FREE: 'FREE',
    OMISE: 'OMISE',
    STRIPE: 'STRIPE',
}

export const PaymentCurrency = {
    THB: 'THB',
    USD: 'USD',
}

export const PaymentDescription = {
    DEFAULT: 'SculptureScreenlock'
}

export const PocketbaseAction = {
    CREATE: 'create',
    UPDATE: 'update',
}

export const OmiseStatusKey = {
    CREATE: 'charge.create',
    COMPLETE: 'charge.complete',
}

export const OmiseStatusName = {
    PENDING: 'pending',
    SUCCESS: 'successful',
}

export const OmiseLinkConfig = {
    TITLE: 'Sculpture Bangkok',
}

export const InterfaceText = {

    GLOBAL_BACK_BUTTON: 'BACK',

    PAYMENT_SCAN_TITLE: 'Please scan Promptpay QR Code',
    PAYMENT_SCAN_SUBTITLE: 'with your mobile banking applications',
    PAYMENT_SCAN_SCULPTURE: 'SCULPTUREBANGKOK',
    PAYMENT_SCAN_CURRENCY: 'THB',
    PAYMENT_SCAN_BACK_BUTTON: 'Back',
    PAYMENT_SCAN_CONFIRM_BUTTON: 'CONFIRM PAYMENT',
    PAYMENT_SCAN_CONFIRM_LOADING: 'Loading...',
    PAYMENT_SCAN_PAYMENT_PROCESSING: '<p>กำลังประมวลผลรายการ...</p><br><p>This transaction is processing...</p>',
    PAYMENT_SCAN_PAYMENT_NOT_FOUND_TITLE: 'Payment Not Found',
    PAYMENT_SCAN_PAYMENT_NOT_FOUND: '<p>ไม่พบรายการชำระเงิน หากท่านได้ชำระเงินเรียบร้อยแล้ว กรุณารอระบบประมวลซักครู่แล้วลองใหม่อีกครั้ง</p><br><p>No payment was found. If you\'ve already made the payment, please wait for a few seconds and try again.</p>',

    PAYMENT_SCAN_PAYMENT_CANCEL_TITLE: 'ยืนยันการออกจากหน้าชำระเงิน',
    PAYMENT_SCAN_PAYMENT_CANCEL: 'SCULPTURE อาจจะใช้เวลาซักครู่ในการประมวลผลการชำระเงิน. <b>หากคุณกดปุ่ม Back หลังจากสแกนจ่ายแล้ว อาจทำให้รายการชำระสูญหาย</b>',
    PAYMENT_SCAN_PAYMENT_CANCEL_STAY: 'อยู่หน้าเดิม',
    PAYMENT_SCAN_PAYMENT_CANCEL_BACK: 'กลับ',

    PAYMENT_SCAN_PAYMENT_CANCEL_TITLE_EN: 'Please confirm exiting this page',
    PAYMENT_SCAN_PAYMENT_CANCEL_EN: 'SCULPTURE might need sometime to process the payment. <b>If you exit this page after finish payment, you might lose your payment!</b>',
    PAYMENT_SCAN_PAYMENT_CANCEL_STAY_EN: 'STAY',
    PAYMENT_SCAN_PAYMENT_CANCEL_BACK_EN: 'EXIT',

    PAYMENT_SCAN_PAYMENT_UNKNOWN_TITLE: 'Unknown Error',
    PAYMENT_SCAN_PAYMENT_UNKNOWN: 'Unknown error occur, Please contact staff for help. (PB)',


    PAYMENT_TRUEMONEY_TITLE: 'PLEASE ENTER YOUR MOBILE NUMBER',
    PAYMENT_TRUEMONEY_SUBTITLE: 'LINKED WITH TRUEMONEY WALLET',
    PAYMENT_TRUEMONEY_TITLE_AFTER: 'PLEASE SCAN QR CODE WITH YOUR MOBILE CAMERA',
    PAYMENT_TRUEMONEY_SUBTITLE_AFTER: 'AND PAY WITH YOUR PHONE',

    PAYMENT_ALIPAY_TITLE: 'PLEASE SCAN QR CODE WITH YOUR MOBILE CAMERA',
    PAYMENT_ALIPAY_SUBTITLE: 'AND PAY WITH YOUR PHONE',


    PAYMENT_LINK_TITLE: 'PLEASE SCAN QR CODE WITH YOUR MOBILE CAMERA',
    PAYMENT_LINK_SUBTITLE: 'AND PAY WITH YOUR PHONE',

    PAYMENT_OPTION_PAY: 'PAY',
    PAYMENT_OPTION_PROMOTION_HEADER: 'PROMOTION',
    PAYMENT_OPTION_PROMOTION_FOUND: 'PROMOTION FOUND',

    PAYMENT_OPTION_DIALOG_HEADER: 'STAFF PIN',
    PAYMENT_OPTION_DIALOG_TITLE: 'ENTER YOUR PIN CODE',
    PAYMENT_OPTION_DIALOG_DEL_BUTTON: 'DEL',
    PAYMENT_OPTION_STANDBY_TEXT: 'ENTER YOUR PROMOTION CODE OR QR',
    PAYMENT_OPTION_PROMOTION_BUTTON: 'PROMOTION CODE',
    PAYMENT_OPTION_PROMOTION_BUTTON_QR: 'Promotion QR',
    PAYMENT_OPTION_PROMOTION_BUTTON_TEXT: 'Promotion Code',
    PAYMENT_OPTION_PROMOTION_TYPE_SELECT: 'PROMOTION',
    PAYMENT_OPTION_PROMOTION_TYPE_SELECT_SUBTITLE: 'SELECT PROMOTION TYPE',
    PAYMENT_OPTION_ENTER_YOUR_PROMOTION_CODE: 'ENTER YOUR CODE',


    PAYMENT_PRICE_TITLE: 'PLEASE CONFIRM YOUR ORDER',
    PAYMENT_PRICE_SUBTITLE: 'OR ADD PROMOTION CODE IF YOU HAVE ONE',
    PAYMENT_PRICE_PROMOTION_BUTTON: 'Got Promotion Code?',
    PAYMENT_PRICE_PROMOTION_REMOVE_BUTTON: 'Remove Promotion',
    PAYMENT_PRICE_BACK_BUTTON: 'Back',
    PAYMENT_PRICE_STANDBY_TEXT: 'SCAN YOUR PROMOTION QR',
    PAYMENT_PRICE_PROMOTION_HEADER: 'PROMOTION',
    PAYMENT_PRICE_PROMOTION_FOUND: 'PROMOTION FOUND',

    PAYMENT_PRICE_PRODUCT_NAME: 'PHOTO (PRINT + FILE)',
    PAYMENT_PRICE_LABEL_DISCOUNT: 'DISCOUNT',
    PAYMENT_PRICE_LABEL_VAT: 'VAT',
    PAYMENT_PRICE_LABEL_NET: 'TOTAL',
    PAYMENT_PRICE_LABEL_CURRENCY: 'THB',

    PROMOTION_FOUND: 'PROMOTION FOUND',

    PAYMENT_SCAN_NOT_PAID_TITLE: 'PAYMENT PROCESSING',
    PAYMENT_SCAN_NOT_PAID_TEXT: 'THIS TRANSACTION IS PROCESSING...',

    HOME_PIN_HEADER: 'ENTER YOUR PIN CODE',
    HOME_PIN_TITLE: 'EXIT APPLICATION',

    PAY_PIN_HEADER: "STAFF PIN",
    PAY_PIN_TITLE: "ENTER YOUR PIN CODE",

    SKIP_PAYMENT_HEADER: "SKIP PAYMENT"
}

export const InterfaceErrorText = {
    APPLICATION_OFFLINE: 'APPLICATION OFFLINE',
    CONNECTION_ERROR: 'CONNECTION ERROR',
    PROMOTION_NOT_FOUND: 'PROMOTION NOT FOUND',
    PROMOTION_ALREADY_USED: 'PROMOTION ALREADY USED',
    PROMOTION_QUOTA_EXCEED: 'PROMOTION QUOTA EXCEED',
    PROMOTION_REVOKED: 'PROMOTION CANCELED',
    PROMOTION_EXPIRED: 'PROMOTION EXPIRED',
    PROMOTION_EXCEED: 'PROMOTION QUOTA FULL',
    INVALID_PROMOTION_TYPE: 'INVALID PROMOTION TYPE',
    USER_NOT_FOUND: 'USER NOT FOUND',

    PAYMENT_SCAN_QR_ERROR_TITLE: 'QR CODE NOT AVAILABLE',
    PAYMENT_SCAN_QR_ERROR_SUBTITLE: 'Please contact staff for assistance',

    PAYMENT_SUCCESS_DSLR_ERROR_TITLE: 'DSLR ERROR',
    PAYMENT_SUCCESS_DSLR_ERROR_TEXT: 'PLEASE CONTACT STAFF FOR ASSISTANCE',

    REQ_ERROR_USER_NOT_FOUND: 'USER NOT FOUND',
    REQ_ERROR_REJECTED: 'YOUR REQUEST IS REJECTED!',
    REQ_UNKNOWN_ERROR: 'UNKNOWN ERROR',
    REQ_TIMEOUT: 'REQUEST TIMEOUT',
    REQ_UNAUTHORIZED: 'UNAUTHORIZED',

}

export const PromotionType = {
    FREE: 'FREE',
    AMOUNT: 'AMOUNT',
    PERCENT: 'PERCENT',
    NONE: 'NONE'
}

export const PromotioCodeType = {
    STATIC: 'STATIC',
    DYNAMIC: 'DYNAMIC',
}

export enum HiddenMenuMode {
    NONE = 'NONE',
    CONFIG = 'CONFIG',
    EXIT = 'EXIT',
    LOGOUT = 'LOGOUT',
}

export const GlobalConfigKey = {
    FREE_WHITELIST_BRANCH: 'free_whitelist_branch',
    SCULPTURE_MONITOR_EMAILS: 'sculpture_monitor_emails',
    FLAG_USE_BRANCH_TIME: 'flag_use_branch_time',
    FLAG_USE_SNOW: 'flag_use_snow',
    APPROVE_REQUEST_CONFIG: 'approve_request_config_screenlock_v2',
    SCULPTURE_BRANCH_HIDE_PAY: 'sculpture_branch_hide_pay',
}

export enum PinDialogEvent {
    SUCCESS = 'SUCCESS',
    FAILED = 'FAILED',
    CLOSE_DIALOG = 'CLOSE_DIALOG',
}

export interface PinDialogVerifyResult {
    mode: HiddenMenuMode
    event: PinDialogEvent,

}

export const RequestReason = [
    {
        id: "4rhzwpizmx41avx",
        code: "test_run",
        label_th: "ทดสอบถ่าย",
        label_en: "Test"
    },
    {
        id: "m4xs67glpyampnv",
        code: "application_issue",
        label_th: "ระบบมีปัญหา",
        label_en: "Application Issue"
    },
    {
        id: "ehyl1bkibjy07du",
        code: "event",
        label_th: "งานอีเวนท์",
        label_en: "Event"
    },
    {
        id: "afv5xgekki6oqp7",
        code: "free",
        label_th: "ถ่ายฟรี",
        label_en: "Free"
    },
    {
        id: "e8nbn5wjtslocmi",
        code: "others",
        label_th: "อื่นๆ",
        label_en: "Others"
    }
]

export enum RequestApprovalState {
    STANDBY = 'standby',
    PENDING = 'pending',
    APPROVED = 'approved',
    REJECTED = 'rejected',
    ERROR = 'error',
    EXPIRED = 'expired',
    NO_NETWORK = 'no_network'
}

export const GlobalProgramInfo = {
    CODE: 'screenlock_v2'
}

export const TimePeriod = {
    TWO_SEC: 2 * 1000,
    THREE_SEC: 3 * 1000,
    THREE_MINUTE: 3 * 60 * 1000,
    FIVE_MINUTES: 5 * 60 * 1000,
    ONE_HOUR: 60 * 60 * 1000
}

export enum PinDialogType {
    REGULAR = 'regular',
    REQUEST_APPROVE = 'request_approve'

}