<app-network-detector (onOnline)="setOnline()" (onOffline)="setOffline()"></app-network-detector>
<app-error-network *ngIf="isOffline"></app-error-network>

<div class="top-bar"></div>
<div class="content-wrapper">
  <div class="flex-container">
    <div class="row mb-5">
      <div class="col-12">
        <button class="solid-button mt-5">
          PROMOTION
        </button>
      </div>
    </div>
    <div class="row mb-5">
      <div class="col-12">
        <h3 class="robotomono-font">PROMOTION TYPE SELECTION</h3>
      </div>
    </div>
    <div class="row mb-5 text-center">
      <div class="col-12">
        <button class="promo-type-item" (click)="openDialog(promotiontext, 'promotiontext')">
          <strong>TEXT CODE</strong>
        </button>
      </div>
    </div>
  </div>
</div>

<button class="btn btn-light fixed-exit pin-button" disabled>
  <img src="assets/img/new/star-icon.png" alt="pin" class="pin-icon">
</button>
<div class="fixed-logo">
  <img src="assets/img/new/sculpture-new.png" alt="" class="logo-icon">
</div>
<button class="back-button fixed-back" (click)="goToBack()">
  {{ TXT_GLOBAL_BACK_BUTTON }}
</button>

<ng-template #promotion let-modal>
  <div class="modal-header robotomono-font">
    <h1 class="modal-title text-center" style="margin-left: auto; margin-right: auto">
      PROMOTION QR
    </h1>
    <button type="button" class="close modal-close-button" aria-label="Close" (click)="dismissAllModal()">
      <span aria-hidden="true" style="color: rgb(0, 0, 0);">&times;</span>
    </button>
  </div>
  <div class="modal-header robotomono-font">
    <h4 class="modal-title text-center" style="margin-left: auto; margin-right: auto"
      [ngClass]="{'promo-error': promoError}">
      {{ standbyText }}
    </h4>
  </div>
  <div class="modal-body robotomono-font p-5" style="background-color: #ffffff">
    <div class="row text-center">
      <img class="img-fluid" src="assets/img/load-cam.gif" alt="" style="margin-left: auto; margin-right: auto"
        *ngIf="showCamLoad" />
    </div>
    <div class="row text-center">
      <div class="col-12 mt-3">
        <button class="btn btn-light btn-block numpad-button" style="font-size: 20px"
          (click)="modal.dismiss('Cross click')">
          CLOSE
        </button>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #promotiontext let-modal>
  <div class="modal-header robotomono-font">
    <h1 class="modal-title text-center" style="margin-left: auto; margin-right: auto">
      PROMOTION CODE
    </h1>
    <button type="button" class="close modal-close-button" aria-label="Close" (click)="dismissAllModal()">
      <span aria-hidden="true" style="color: rgb(0, 0, 0);">&times;</span>
    </button>
  </div>
  <div class="modal-header robotomono-font">
    <h4 class="modal-title text-center" style="margin-left: auto; margin-right: auto"
      [ngClass]="{'promo-error': promoError}">
      {{ standbyText }}
    </h4>
  </div>
  <div class="modal-body robotomono-font p-5" style="background-color: #ffffff">
    <div class="row text-center">
      <div class="form-group text-center" style="width: 100%;">
        <input type="text" class="form-control" id="promo_code" placeholder="CODE"
          style="font-size: 30px; border: 1px solid black" [(ngModel)]="enteredPromo" />
      </div>
    </div>
    <div class="row text-center">
      <div class="col-12 mt-3">
        <button class="btn btn-light btn-block numpad-button" style="font-size: 20px" (click)="submitPromo()">
          APPLY PROMOTION
        </button>
      </div>
    </div>
  </div>
</ng-template>

<app-custom-keyboard *ngIf="showKeyboard" (keyClickEvent)="checkCharacter($event)"></app-custom-keyboard>
<app-loading *ngIf="commonService.isLoading"></app-loading>