import { Location } from '@angular/common';
import { Component, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ApproveRequestConfig } from 'src/app/interfaces/interface';
import { CommonService } from 'src/app/services/common/common.service';
import { DataPocketService } from 'src/app/services/data-pocket/data-pocket.service';
import { AppRoute } from 'src/app/shared/constants/app-route.constant';
import {
  GlobalConfigKey,
  HiddenMenuMode,
  InterfaceText,
  PinDialogType,
  PinDialogVerifyResult,
  StorageKey,
} from 'src/app/shared/constants/global-config.constant';


@Component({
  selector: 'app-payment-or-promo',
  templateUrl: './payment-or-promo.component.html',
  styleUrls: ['./payment-or-promo.component.scss']
})
export class PaymentOrPromoComponent {

  constructor(
    private router: Router,
    private ngbModal: NgbModal,
    private dataPocketService: DataPocketService,
    public commonService: CommonService,
    private activatedRoute: ActivatedRoute,
    private location: Location
  ) {

    this.activatedRoute.queryParams.subscribe(params => {
      console.log(params['pin']);
      this.isOpenPinDialog = params['pin'];
    })

  }

  TXT_PAYMENT_OPTION_DIALOG_HEADER = InterfaceText.PAYMENT_OPTION_DIALOG_HEADER;
  TXT_PAYMENT_OPTION_DIALOG_TITLE = InterfaceText.PAYMENT_OPTION_DIALOG_TITLE;
  TXT_PAYMENT_OPTION_DIALOG_DEL_BUTTON = InterfaceText.PAYMENT_OPTION_DIALOG_DEL_BUTTON;
  TXT_PAYMENT_OPTION_STANDBY_TEXT = InterfaceText.PAYMENT_OPTION_STANDBY_TEXT;
  TXT_PAYMENT_OPTION_PROMOTION_BUTTON = InterfaceText.PAYMENT_OPTION_PROMOTION_BUTTON;
  TXT_PAYMENT_OPTION_PROMOTION_BUTTON_QR = InterfaceText.PAYMENT_OPTION_PROMOTION_BUTTON_QR;
  TXT_PAYMENT_OPTION_PROMOTION_BUTTON_TEXT = InterfaceText.PAYMENT_OPTION_PROMOTION_BUTTON_TEXT;
  TXT_PAYMENT_OPTION_PROMOTION_TYPE_SELECT = InterfaceText.PAYMENT_OPTION_PROMOTION_TYPE_SELECT;
  TXT_PAYMENT_OPTION_PROMOTION_TYPE_SELECT_SUBTITLE = InterfaceText.PAYMENT_OPTION_PROMOTION_TYPE_SELECT_SUBTITLE;
  TXT_PAYMENT_OPTION_PAY = InterfaceText.PAYMENT_OPTION_PAY;
  TXT_PAYMENT_OPTION_PROMOTION_HEADER = InterfaceText.PAYMENT_OPTION_PROMOTION_HEADER;
  TXT_GLOBAL_BACK_BUTTON = InterfaceText.GLOBAL_BACK_BUTTON;
  TXT_PAYMENT_OPTION_ENTER_YOUR_PROMOTION_CODE = InterfaceText.PAYMENT_OPTION_ENTER_YOUR_PROMOTION_CODE;

  show = true;

  modalRef: NgbModalRef;
  typed: string = "";
  promoError: boolean = false;
  standbyText: string = InterfaceText.PAYMENT_OPTION_STANDBY_TEXT;
  showCamLoad: boolean = true;
  isOffline: boolean = false;
  showKeyboard = false;
  enteredPromo = '';

  isGotDiscount: boolean = false;
  fullPrice: number = 0;
  vatPrice: number = 0;
  discountPrice: number = 0;
  deductedPrice: number = 0;
  vatPercent: number = 0
  totalPrice: number = 0;

  isFreeWhitelist: boolean = false;
  showPayButton: boolean = true;

  currentHiddenMenuMode: HiddenMenuMode = HiddenMenuMode.NONE;
  @ViewChild('content') content;
  isOpenPinDialog = false;
  public PinDialogType = PinDialogType;
  usePinDialogType: PinDialogType = PinDialogType.REGULAR;
  approveRequestConfig: ApproveRequestConfig = null;

  async ngAfterViewInit() {
    this.commonService.isLoading = true;
    try {
      await this.checkFreeWhiteList();
      await this.checkHidePayButton();
      await this.checkUsePinDialogType();

      if (this.isOpenPinDialog) {
        this.openPIN(this.content, null)
      }
    }
    catch (err) {
      console.log(err);
    }
    finally {
      this.commonService.isLoading = false;
    }
  }

  async checkFreeWhiteList() {
    await this.dataPocketService.loginPocketbase();
    const configResult = await this.dataPocketService.getGlobalConfig(GlobalConfigKey.FREE_WHITELIST_BRANCH).toPromise();
    if (configResult) {
      if (configResult.items.length > 0) {
        if (configResult.items[0].value.includes(localStorage.getItem(StorageKey.BRANCH_CODE))) {
          this.router.navigate([AppRoute.PAYMENT_SUCCESS]);
        }
      }
      console.log(configResult);
    }
  }

  async checkHidePayButton() {
    await this.dataPocketService.loginPocketbase();
    const configResult = await this.dataPocketService.getGlobalConfig(GlobalConfigKey.SCULPTURE_BRANCH_HIDE_PAY).toPromise();
    if (configResult) {
      if (configResult.items.length > 0) {
        if (configResult.items[0].value.includes(localStorage.getItem(StorageKey.BRANCH_CODE))) {
          this.showPayButton = false;
        }
      }
      console.log(configResult);
    }
  }

  async checkUsePinDialogType() {
    try {
      await this.dataPocketService.loginPocketbase();
      this.approveRequestConfig = await this.dataPocketService.getGlobalConfig(GlobalConfigKey.APPROVE_REQUEST_CONFIG).toPromise() as ApproveRequestConfig;

      if (this.approveRequestConfig) {
        if (this.approveRequestConfig.items.length > 0) {

          if(this.approveRequestConfig.items[0].value.is_use_request) {

            if (!this.approveRequestConfig.items[0].value.use_all_branch) { // use for some branch only from specified list
              if (this.approveRequestConfig.items[0].value.branch_use.includes(localStorage.getItem(StorageKey.BRANCH_CODE))) {
                this.usePinDialogType = PinDialogType.REQUEST_APPROVE;
              }
              else {
                this.usePinDialogType = PinDialogType.REGULAR;
              }
            }
            else { // use for all branch except some branch
              if (this.approveRequestConfig.items[0].value.branch_skip.includes(localStorage.getItem(StorageKey.BRANCH_CODE))) {
                this.usePinDialogType = PinDialogType.REGULAR;
              }
              else {
                this.usePinDialogType = PinDialogType.REQUEST_APPROVE;
              }
            }

          }
          else {
            this.usePinDialogType = PinDialogType.REGULAR;
          }

        }
        else {
          this.usePinDialogType = PinDialogType.REGULAR;
        }
      }
      else {
        this.usePinDialogType = PinDialogType.REGULAR;
      }
    }
    catch (err) {
      this.usePinDialogType = PinDialogType.REGULAR;
    }
  }

  openPIN(content, mode: string) {
    console.log(content);
    if (mode === 'typepromo') {
      this.modalRef = this.ngbModal.open(content);
    }
    else if (mode === 'promotion') {
      this.modalRef.dismiss();
      this.modalRef = this.ngbModal.open(content);
    }
    else if (mode === 'promotiontext') {
      this.modalRef.dismiss();
      this.modalRef = this.ngbModal.open(content, { backdrop: false });
      this.showKeyboard = true;
    }
    else {
      this.location.replaceState(`/payment-or-promo?pin=true`);
      this.modalRef = this.ngbModal.open(content, { centered: true });
    }
  }

  goToPrice() {
    localStorage.setItem(StorageKey.PROMO_USE, 'false');
    this.router.navigate([AppRoute.PAYMENT_PRICE]);
  }

  goToHome() {
    this.router.navigate([AppRoute.HOME]);
  }

  goToPromoType() {
    this.router.navigate([AppRoute.PROMO_TYPE]);
  }

  setOnline() {
    this.isOffline = false;
  }

  setOffline() {
    this.isOffline = true;
  }

  handleCloseDialog(data: PinDialogVerifyResult) {
    console.log('Close dialog')
    this.modalRef.dismiss();
    this.showKeyboard = false;
    this.showCamLoad = true;
    this.location.replaceState(`/payment-or-promo`);
  }

  handleSuccessVerify(data: PinDialogVerifyResult) {
    console.log('Success verify')
    this.modalRef.close();
    this.router.navigate([AppRoute.PAYMENT_SUCCESS]);
  }

  handleFailedVerify(data: PinDialogVerifyResult) {
    //do nothing yet
    console.log('Failed verify');
  }

}
